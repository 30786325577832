<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th rowspan="2" class="text-center">Date</th>
              <th rowspan="2" class="text-center">Description</th>
              <th rowspan="2" class="text-center">Cost</th>
              <th rowspan="2" class="text-center">Estimated Life</th>
              <th rowspan="2" class="text-center">Last Year Acc. Depreciation</th>
              <th rowspan="2" class="text-center">Beg. Book Value</th>
              <th colspan="14" class="text-center">Amortization</th>
              <th rowspan="2" class="text-center">End. Book Value</th>
            </tr>
            <tr>
              <th class="text-center">Jan</th>
              <th class="text-center">Feb</th>
              <th class="text-center">Mar</th>
              <th class="text-center">Apr</th>
              <th class="text-center">Mei</th>
              <th class="text-center">Jun</th>
              <th class="text-center">Jul</th>
              <th class="text-center">Jan</th>
              <th class="text-center">Aug</th>
              <th class="text-center">Sep</th>
              <th class="text-center">Oct</th>
              <th class="text-center">Nov</th>
              <th class="text-center">Dec</th>
              <th class="text-center">Total ytd</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
              <td>6</td>
              <td>7</td>
              <td>8</td>
              <td>9</td>
              <td>10</td>
              <td>11</td>
              <td>12</td>
              <td>13</td>
              <td>14</td>
              <td>15</td>
              <td>16</td>
              <td>17</td>
              <td>18</td>
              <td>19</td>
              <td>20</td>
              <td>21</td>
            </tr>
            <tr>
              <td colspan="5" class="text-right">Total</td>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
              <td>6</td>
              <td>7</td>
              <td>8</td>
              <td>9</td>
              <td>10</td>
              <td>11</td>
              <td>12</td>
              <td>13</td>
              <td>14</td>
              <td>15</td>
              <td>16</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid grey;
}
</style>
